<template>
    <div class="page-tech-info g-page">
        <div class="wp">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path:'/'}">首页</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path:'/TechInfo'}">技术资料</el-breadcrumb-item>
                <el-breadcrumb-item>详情</el-breadcrumb-item>
            </el-breadcrumb>
            <div class="tech-info-det" v-if="TechInfo">
                 <div class="title">
                     <h1>{{ TechInfo.title }}</h1>
                     <p>发件人:  {{ TechInfo.sender }} {{ TechInfo.publishTime }}</p>
                 </div>
                <div class="content">
                    <p class="" v-html="`${TechInfo.contentText}`"></p>
                </div>
                <div class="download">
                    <div @click="downloadfile(TechInfo.attachFile)" class="text cursorPointer">
                        《{{ TechInfo.title }}》
                        <img src="@/assets/img/pdf.png" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {articleTechnicalDetail} from "@/api/home";

export default {
    name: "techInfo",
    data() {
        return {
            TechInfo:'',

        }
    },
    mounted() {
        console.log(this.$route.query) // 获取传递参数
        this.getarticleTechnicalDetail(this.$route.query.id)

    },
    methods:{
        downloadfile(file){ //下载文件
            window.open(file);
        },

        getarticleTechnicalDetail(id){
            articleTechnicalDetail({id:id}).then((res) => {
                if (res.data.code==200) {
                    this.TechInfo=res.data.data
                    this.TechInfo.contentText=this.$utils.showHtml(this.TechInfo.contentText)
                }

            })
        },
    },
}
</script>

<style scoped>

.page-tech-info .download .text{
    display: flex;
    align-items: center;
    color: #eb6100;
    font-size: 16px;
}
</style>
